<template>
  <div class="container">

    <div class="header">
      <div class="flagship-list">
        <template v-if="firtLevel === 1">
          <van-button :class="{'active':flagshipType === 1}" type="primary" @click="switchTab(1)">金星旗舰店</van-button>
          <van-button :class="{'active':flagshipType === 2}" type="primary" @click="switchTab(2)">银星旗舰店</van-button>
        </template>
        <van-button class="active" type="primary" :to="'/PKResult?token=' + token" v-if="source === 'result'">获奖记录</van-button>
        <div class="flagship-type" v-if="firtLevel === 2">
          <img v-if="flagshipType === 1" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903115717884.png" />
          <img v-else src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903115743266.png" />
        </div>
      </div>
    </div>
    <div class="rank-content">
      <van-row class="van-head">
        <van-col span="3"></van-col>
        <van-col span="3"></van-col>
        <van-col span="7" class="user-name">姓名</van-col>
        <van-col span="7">订货业绩</van-col>
        <van-col span="4">排名情况</van-col>
      </van-row>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-row v-for="(item,index) in list" :key="index">
          <van-col class="rank-num" span="3">
            <img v-if="item.pkRanking === 1" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903134649701.png" />
            <img v-else-if="item.pkRanking === 2" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903134705868.png" />
            <img v-else-if="item.pkRanking === 3" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903134716677.png" />
            <div class="ivv-serial" v-else>
              <span>No.</span>
              <div>{{item.pkRanking}}</div>
            </div>
          </van-col>
          <van-col span="3" class="user-head">
            <img :src="item.headerImg ? item.headerImg :'/img/100.b3582921.png'" />
          </van-col>
          <van-col span="7" class="user-info">
            <div class="ellipsis1"><template v-if="item.isMe">(我的)</template>{{ item.agentName }}</div>
            <!-- <p class="ellipsis1">{{item.agentCode}}</p> -->
          </van-col>
          <van-col span="7" class="user-perf">￥{{ item.performance }}</van-col>
          <van-col class="ivv-reward ellipsis2" :class="{'horizontal':item.uporDown === 0}" span="4">
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210323164100130.png" v-if="item.uporDown === 1" />
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210323164118801.png" v-else-if="item.uporDown === 2" />
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210324114646176.png" v-else />
          </van-col>
        </van-row>
      </van-list>
    </div>
    <toolbar :token="token" />
  </div>
</template>

<script>
import toolbar from "../../../components/toolbar";
import { deliveryMessage } from "../../../utils/appUtil";
let isFirstCome = true;
export default {
  name: "FlagshipIndex",
  data() {
    return {
      flagshipType: 1, //1金星 2银星
      loading: false,
      finished: false,
      list: [],
      ps: 20,
      pg: 0,
      token: '',
      source: '',
      firtLevel: 2,
    }
  },
  components: { toolbar },
  created() {
    let { type } = this.$route.params;
    let { token, source } = this.$route.query;
    this.flagshipType = +type;
    if (this.flagshipType === 1) this.firtLevel = 1;
    if (token) this.token = token;
    if (source) {
      this.source = source;
    }
  },
  methods: {
    //切换金星银星
    switchTab(type) {
      if (this.loading) return;
      this.flagshipType = type;
      this.list = [];
      this.finished = false;
      this.loading = false;
      this.pg = 0;
    },
    onLoad() {
      this.pg++;
      this.get("/OnlyOnce/HeroCompetition/GetPkRankList", {
        levelid: this.flagshipType + 1,
        pageIndex: this.pg,
        pageSize: this.ps
      }, 2).then(res => {
        this.loading = false;
        if (!res.total && this.source != 'result') {
          this.$router.push("/PKResult?token=" + this.token);
        }
        if (res.code === 1) {
          let { msgInfo, list } = res.response;
          this.list = list;
          this.finished = true;
          if (msgInfo && isFirstCome) {
            isFirstCome = false;
            this.$dialog.confirm({ title: "提示", message: msgInfo, confirmButtonText: "采购订货" }).then(() => {
              if (this.token) {
                return deliveryMessage({ 'action': 'jumpShopkeeperOrder' })
              }
              window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html";
            })
          }
        } else {
          this.finished = true;
          this.$dialog.confirm({ title: "提示", message: res.msg, confirmButtonText: "采购订货" }).then(() => {
            if (this.token) {
              deliveryMessage({ 'action': 'jumpShopkeeperOrder' })
            } else {
              window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html";
            }
          })
        }
      }).catch(() => {
        this.loading = false;
        this.finished = true;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 120px 25px 11px;
  background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210913110855394.png")
    no-repeat #c5e1ea;
  background-size: 100% auto;
  .header {
    position: relative;
    padding-bottom: 11px;
    text-align: center;
    .flagship-list {
      display: flex;
      padding: 11px 0;
      align-items: center;
      justify-content: space-between;
      .van-button {
        height: 24px;
        border-color: #fff;
        border-radius: 32px;
        background: linear-gradient(to bottom, #f4f4f4, #afafaf);
        background: -webkit-linear-gradient(to bottom, #f4f4f4, #afafaf);
        background: -moz-linear-gradient(to bottom, #f4f4f4, #afafaf);
        box-shadow: 0 3px 5px #c3c3c3;
        .van-button__content {
          .van-button__text {
            font-size: 12px;
          }
        }
        &.active {
          background: linear-gradient(to bottom, #d5f5ff, #44a8d1);
          background: -webkit-linear-gradient(to bottom, #d5f5ff, #44a8d1);
          background: -moz-linear-gradient(to bottom, #d5f5ff, #44a8d1);
          box-shadow: 0 3px 5px #75b0c9;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .flagship-type {
      img {
        width: 100px;
      }
    }
  }

  .rank-content {
    text-align: center;
    padding: 44px 15px 11px;
    background-color: #fff;
    position: relative;
    height: calc(100% - 120px);
    &:before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      height: 40px;
      background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903133703180.png")
        no-repeat;
      background-size: 100% auto;
    }

    .van-head {
      padding: 11px 0 11px;

      .user-name {
        text-align: left;
      }
    }

    .van-list {
      position: absolute;
      top: 88px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      .van-row {
        display: flex;
        height: 45px;
        display: -webkit-flex;
        align-items: center;
        position: relative;

        .user-head {
          text-align: left;

          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
          }
        }

        .user-info {
          color: #000000;
          text-align: left;

          p {
            color: #999999;
            font-size: 9px;
            padding-top: 3px;
          }
        }

        .user-perf {
          color: #fd0000;
          font-size: 14px;
        }

        .rank-num {
          > img {
            width: 30px;
          }

          .ivv-serial {
            span {
              color: #fff;
              padding: 0.02rem 0.1rem;
              font-size: 0.1945rem;
              border-radius: 0.19rem;
              background-color: #cccccc;
            }

            div {
              font-size: 0.6889rem;
            }
          }
        }

        .ivv-reward {
          color: #f9b022;
          font-size: 0.364rem;

          img {
            width: 10px;
          }

          &.horizontal {
            img {
              width: 17px;
            }
          }
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #f5f5f5;
        }
      }
    }
  }
}
</style>
